
<template>
	<div>
		<v-app-bar app class="navbar">
			<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
			<v-btn icon="" to="/">
				<v-icon>mdi-home</v-icon>
			</v-btn>
			<v-app-bar-title>{{$store.getters.language.app_name}}</v-app-bar-title>
			<v-spacer></v-spacer>
			<v-btn icon class="mx-1" dark @click="$vuetify.theme.dark = !$vuetify.theme.dark">
				<v-icon>mdi-theme-light-dark</v-icon>
			</v-btn>
			<v-btn icon="" @click="logout">

				<v-icon>mdi-logout</v-icon>
			</v-btn>
		</v-app-bar>
		<v-navigation-drawer v-model="drawer" app :right="$store.getters.language.rtl">
			<template v-slot:prepend>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title class="text-center">{{$store.getters.language.app_name}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</template>
			<v-divider class="mt-3"></v-divider>
			<v-list shaped>
				<v-list-group>
					<template v-slot:activator>
						<v-list-item-content>
							<v-list-item-title>Online Shopping</v-list-item-title>
						</v-list-item-content>
					</template>
					<v-list-item to="/customers-list">
						<v-list-item-content>
							<v-list-item-title>Data Entery</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<!-- <v-list-item to="/fast-search">
						<v-list-item-content>
							<v-list-item-title> Fast Search</v-list-item-title>
						</v-list-item-content>
					</v-list-item> -->
					<v-list-item to="/search">
						<v-list-item-content>
							<v-list-item-title> Search </v-list-item-title>
						</v-list-item-content>
					</v-list-item>
			
					
                    <!-- <v-list-item to="/shipping_package_files-list">
                        <v-list-item-content>
                            <v-list-item-title>Shipping Package Files</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    -->
					<!-- <v-list-item to="/users-list">
                    <v-list-item-content>
                        <v-list-item-title >Users</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->
					<!-- <v-list-item to="/orders-list">
                    <v-list-item-content>
                        <v-list-item-title >Orders</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->
					<!-- <v-list-item to="/file_attachments-list">
                    <v-list-item-content>
                        <v-list-item-title >File Attachments</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->
					<!-- <v-list-item to="/packed_items-list">
                    <v-list-item-content>
                        <v-list-item-title >Packed Items</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->

					<v-list-item to="/purchasing_items-list" v-if="user.user_role==1">
						<v-list-item-content>
							<v-list-item-title>Purchasing Items</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item to="/important_items-list" v-if="user.user_role==1">
						<v-list-item-content>
							<v-list-item-title>Important Items</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<v-list-item to="/incomplete_order_items-list">
						<v-list-item-content>
							<v-list-item-title>Incomplete items</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item to="/soldout_order_items-list">
						<v-list-item-content>
							<v-list-item-title>Sold Out items</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
                      <v-list-item to="/order-search" >
                        <v-list-item-content>
                            <v-list-item-title>Search Packed Orders</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
					<!-- <v-list-item to="/packed_orders-list">
						<v-list-item-content>
							<v-list-item-title>Packed Orders</v-list-item-title>
						</v-list-item-content>
					</v-list-item> -->
					<!-- <v-list-item to="/shop_sales-list">
						<v-list-item-content>
							<v-list-item-title>Shop Sales</v-list-item-title>
						</v-list-item-content>
					</v-list-item> -->
					<v-list-item to="/admin_edits" v-if="user.user_role!=0">
						<v-list-item-content>
							<v-list-item-title>Admin Edit</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item to="/store_information-list">
						<v-list-item-content>
							<v-list-item-title>Store Information</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item to="/reports" v-if="user.user_role!=0">
						<v-list-item-content>
							<v-list-item-title>Reports</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item to="/reportsemployee" >
						<v-list-item-content>
							<v-list-item-title>Reports Employee</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
                  
                    <v-list-item to="/shipped_packages-list">
						<v-list-item-content>
							<v-list-item-title>Shipped Packages</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
                    <v-list-item to="/customer_users-list">
						<v-list-item-content>
							<v-list-item-title>Customers Accounts</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
                    		<v-list-item to="/order_items-list">
						<v-list-item-content>
							<v-list-item-title>Report Items</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>
				<v-list-group>
					<template v-slot:activator>
						<v-list-item-content>
							<v-list-item-title>Shop</v-list-item-title>
						</v-list-item-content>
					</template>
					<v-list-item to="/shop_items-list" >
						<v-list-item-content>
							<v-list-item-title>Shop Items</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item to="/shop_items-view" >
						<v-list-item-content>
							<v-list-item-title>Shop Items View</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item to="/shop_options-list" >
						<v-list-item-content>
							<v-list-item-title>Options</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item v-for="(item , index) in navigation_links" :key="index" :to="item.path">
						<v-list-item-content>
							<v-list-item-title>{{item.title}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>
                <v-list-group>
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title>Delivery</v-list-item-title>
                        </v-list-item-content>
                                            </template>

                        <v-list-item v-for="(item , index) in driver_navigation_links" :key="index" :to="item.path">
						<v-list-item-content>
							<v-list-item-title>{{item.title}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
                </v-list-group>
                      
			</v-list>
		</v-navigation-drawer>
	</div>
</template>
<script>
	export default {
		data: function () {
			return {
				drawer: null,
			}
		},
		computed: {
			languages() {
				return this.$store.getters.languages
			},
			user() {
				return this.$store.getters.user
			},
			// navigation_links() {
			// 	return [

			// 		{
			// 			path: '/customers-list',
			// 			title: 'Data Entery',
			// 		},

			// 		// {
			// 		//     path : '/file_attachments-list',
			// 		//     title : 'File Attachments',
			// 		// },

			// 		{
			// 			path: '/order_items-list',
			// 			title: 'Search',
			// 		},

			// 		// {
			// 		// 	path: '/orders-list',
			// 		// 	title: 'Ordering Comming Soon',
			// 		// },

			// 		{
			// 			path: '/shop_sales-list',
			// 			title: 'Shop Sales',
			// 		},

			// 		{
			// 			path: '/purchasing_items-list',
			// 			title: 'Purchaing Items',
			// 		},


			// 		// {
			// 		// 	path: '/users-list',
			// 		// 	title: 'Users',
			// 		// },
			// 		// {
			// 		// 	path: '/packed_items-list',
			// 		// 	title: 'Packed Items',
			// 		// },

			// 		{
			// 			path: '/packed_orders-list',
			// 			title: 'Packed Orders',
			// 		},
			// 		// {
			// 		// 	path: '/reports',
			// 		// 	title: 'Reports',
			// 		// },
			// 		{
			// 			path: '/admin_edits',
			// 			title: 'Admin Edits',
			// 		},
			// 	]
			// },
driver_navigation_links(){
    return[
			// {
			// 			path: '/driver_bag_orders-list',
			// 			title: 'Driver Bag Orders',
			// 		},

					{
						path: '/driver_bags-list',
						title: 'Driver Bags',
					},

					{
						path: '/drivers-list',
						title: 'Drivers',
					},
                    {
                        path: '/driver_bags-view',
                        title: 'Driver Bags View',
                    }
    ]
},
			navigation_links() {
				return [
					//  {
					//                         path : '/shop_invoice_items-list',
					//                         title : 'Shop Invoice Items',
					//                     },
					{
						path: '/shop_invoices_direct-list',
						title: 'Shop Invoices Direct',
					},
					{
						path: '/shop_invoices-list',
						title: 'Shop Invoices',
					},
					{
						path: '/shop_invoices_view-list',
						title: 'Shop Invoices View',
					},
		
					// {
					// 	path: '/item_colors-list',
					// 	title: 'Item Colors',
					// },



					// {
					// 	path: '/item_sizes-list',
					// 	title: 'Item Sizes',
					// },

					// {
					// 	path: '/item_types-list',
					// 	title: 'Item Types',
					// },

					// {
					// 	path: '/item_types_view-list',
					// 	title: 'Item Types View',
					// },

					// {
					// 	path: '/shop_item_histories-list',
					// 	title: 'Shop Item Histories',
					// },

					// {
					// 	path: '/shop_item_moves-list',
					// 	title: 'Shop Item Moves',
					// },

					// {
					// 	path: '/shop_items-list',
					// 	title: 'Shop Items',
					// },

					// {
					// 	path: '/shop_items_view-list',
					// 	title: 'Shop Items View',
					// },
					// {
					// 	path: '/file_attachments-list',
					// 	title: 'File Attachments',
					// }

				]
			},
		},
		methods: {
			logout() {
				this.$store.dispatch('logout');
			},

		},
	}
</script>
        