
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('./../views/Home.vue'),
        meta: {
            auth: false,
            title: 'Home'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('./../views/Login.vue'),
        meta: {
            auth: false,
            title: 'Login'
        }
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('./../views/ResetPassword.vue'),
        meta: {
            auth: false,
            title: 'Login'
        }
    },

    {
        path: '/customer_data/:customer_id',
        name: 'CustomerData',
        component: () => import('./../views/CustomerData.vue'),
        meta: {
            auth: true,
            title: 'Customer Data'
        }

    },
    {
        path: '/customer-login',
        name: 'CustomerLogin',
        component: () => import('./../views/CustomerLogin.vue'),
        meta: {
            auth: true,
            title: 'Customer Login'
        }
    },
    {
        path: '/customers-list',
        name: 'ListCustomers',
        component: () => import('./../views/Customers/List.vue'),
        meta: {
            auth: true,
            title: 'Customers List'
        }
    },
    {
        path: '/customers-list/:id',
        name: 'ViewCustomers',
        component: () => import('./../views/Customers/Edit.vue'),
        meta: {
            auth: true,
            title: 'Customers Edit'
        }
    },
    {
        path: '/file_attachments-list',
        name: 'ListFile_attachments',
        component: () => import('./../views/File_attachments/List.vue'),
        meta: {
            auth: true,
            title: 'File_attachments List'
        }
    },
    {
        path: '/file_attachments-list/:id',
        name: 'ViewFile_attachments',
        component: () => import('./../views/File_attachments/Edit.vue'),
        meta: {
            auth: true,
            title: 'File_attachments Edit'
        }
    },
    {
        path: '/order_items-list',
        name: 'ListOrder_items',
        component: () => import('./../views/Order_items/List.vue'),
        meta: {
            auth: true,
            title: 'Order_items List'
        }
    },
    {
        path: '/fast-search',
        name: 'FastSearch',
        component: () => import('./../views/Order_items/FastSearch.vue'),
        meta: {
            auth: true,
            title: 'Fast Search'
        }
    },
    {
        path: '/search',
        name: 'Search',
        component: () => import('./../views/Search.vue'),
        meta: {
            auth: true,
            title: ' Search'
        }
    },
    {
        path: '/order-search',
        name: 'Search_Orders',
        component: () => import('./../views/SearchOrders.vue'),
        meta: {
            auth: true,
            title: ' Search Orders'
        }
    },
     {
        path:'/packed-orders-direct',
        name:'PackedOrdersDirect',
        component: () => import('./../views/Packed_orders/PackedOrdersDirect.vue'),
        meta: {
            auth: true,
            title: 'Packed Orders Direct'
        }
        
    },
    {
        path: '/admin_order_items-list',
        name: 'Admin_ListOrder_items',
        component: () => import('./../views/Order_items/AdminList.vue'),
        meta: {
            auth: true,
            title: 'Admin order items List'
        }
    },
    {
        path: '/detail_admin_order_items-list',
        name: 'Detail_Admin_ListOrder_items',
        component: () => import('./../views/Order_items/AdminListDetial.vue'),
        meta: {
            auth: true,
            title: 'Detail Admin order items List'
        }
    },
    {
        path: '/incomplete_order_items-list',
        name: 'Incomplete_ListOrder_items',
        component: () => import('./../views/Order_items/IncompleteList.vue'),
        meta: {
            auth: true,
            title: 'Incomplete order items List'
        }
    },
    {
        path: '/soldout_order_items-list',
        name: 'Soldout_ListOrder_items',
        component: () => import('./../views/Order_items/SoldoutList.vue'),
        meta: {
            auth: true,
            title: 'Soldout order items List'
        }
    },
    {
        path: '/purchasing_items-list',
        name: 'Purchasing_items',
        component: () => import('./../views/Order_items/PurchasingList.vue'),
        meta: {
            auth: true,
            title: 'Purchasing List'
        }
    },
    {
        path: '/important_items-list',
        name: 'Important_items',
        component: () => import('./../views/Order_items/ImportantList.vue'),
        meta: {
            auth: true,
            title: 'Important List'
        }
    },
    {
        path: '/order_items-list/:id',
        name: 'ViewOrder_items',
        component: () => import('./../views/Order_items/Edit.vue'),
        meta: {
            auth: true,
            title: 'Order_items Edit'
        }
    },
    {
        path: '/orders-list',
        name: 'ListOrders',
        component: () => import('./../views/Orders/List.vue'),
        meta: {
            auth: true,
            title: 'Orders List'
        }
    },
    {
        path: '/orders-list/:id',
        name: 'ViewOrders',
        component: () => import('./../views/Orders/Edit.vue'),
        meta: {
            auth: true,
            title: 'Orders Edit'
        }
    },
    {
        path: '/shop_sales-list',
        name: 'ListShop_sales',
        component: () => import('./../views/Shop_sales/List.vue'),
        meta: {
            auth: true,
            title: 'Shop_sales List'
        }
    },
    {
        path: '/shop_sales-list/:id',
        name: 'ViewShop_sales',
        component: () => import('./../views/Shop_sales/Edit.vue'),
        meta: {
            auth: true,
            title: 'Shop_sales Edit'
        }
    },
    {
        path: '/users-list',
        name: 'ListUsers',
        component: () => import('./../views/Users/List.vue'),
        meta: {
            auth: true,
            title: 'Users List'
        }
    },
    {
        path: '/users-list/:id',
        name: 'ViewUsers',
        component: () => import('./../views/Users/Edit.vue'),
        meta: {
            auth: true,
            title: 'Users Edit'
        }
    },
    {
        path: '/packed_items-list',
        name: 'ListPacked_items',
        component: () => import('./../views/Packed_items/List.vue'),
        meta: {
            auth: true,
            title: 'Packed_items List'
        }
    },
    {
        path: '/packed_items-list/:id',
        name: 'ViewPacked_items',
        component: () => import('./../views/Packed_items/Edit.vue'),
        meta: {
            auth: true,
            title: 'Packed_items Edit'
        }
    },
    {
        path: '/packed_orders-list',
        name: 'ListPacked_orders',
        component: () => import('./../views/Packed_orders/List.vue'),
        meta: {
            auth: true,
            title: 'Packed_orders List'
        }
    },
    {
        path: '/packed_orders-list/:id',
        name: 'ViewPacked_orders',
        component: () => import('./../views/Packed_orders/Edit.vue'),
        meta: {
            auth: true,
            title: 'Packed_orders Edit'
        }
    },
    {
        path: '/reports/',
        name: 'Reports',
        component: () => import('./../views/Reports/Reports.vue'),
        meta: {
            auth: true,
            title: 'Help'
        }
    },
    {
        path: '/items-reports/',
        name: 'ItemsReports',
        component: () => import('./../views/Reports/Reports/Items.vue'),
        meta: {
            auth: true,
            title: 'ItemsReports'
        }
    },
    {
        path: '/admin_edits/',
        name: 'AdminEdits',
        component: () => import('./../views/AdminEdits.vue'),
        meta: {
            auth: true,
            title: 'Admin Edits'
        }
    },
    {
        path: '/reportsemployee',
        name: 'ReportsEmployee',
        component: () => import('./../views/Reports/ReportsEmployee.vue'),
        meta: {
            auth: true,
            title: 'Reports Employee'
        }


    },
    {
        path: '/adminlist_customers/',
        name: 'CustomerEdits',
        component: () => import('./../views/Customers/AdminList.vue'),
        meta: {
            auth: true,
            title: 'Admin Customer Edits'
        }
    },
    {
        path: '/store_information-list',
        name: 'ListStore_information',
        component: () => import('./../views/Store_information/List.vue'),
        meta: {
            auth: true,
            title: 'Store_information List'
        }
    },
    {
        path: '/store_information-list/:id',
        name: 'ViewStore_information',
        component: () => import('./../views/Store_information/Edit.vue'),
        meta: {
            auth: true,
            title: 'Store_information Edit'
        }
    },

    {
        path: '/item_colors-list',
        name: 'ListItem_colors',
        component: () => import('./../views/Item_colors/List.vue'),
        meta: {
            auth: true,
            title: 'Item_colors List'
        }
    },
    {
        path: '/item_colors-list/:id',
        name: 'ViewItem_colors',
        component: () => import('./../views/Item_colors/Edit.vue'),
        meta: {
            auth: true,
            title: 'Item_colors Edit'
        }
    },

    {
        path: '/item_sizes-list',
        name: 'ListItem_sizes',
        component: () => import('./../views/Item_sizes/List.vue'),
        meta: {
            auth: true,
            title: 'Item_sizes List'
        }
    },
    {
        path: '/item_sizes-list/:id',
        name: 'ViewItem_sizes',
        component: () => import('./../views/Item_sizes/Edit.vue'),
        meta: {
            auth: true,
            title: 'Item_sizes Edit'
        }
    },

    {
        path: '/item_types-list',
        name: 'ListItem_types',
        component: () => import('./../views/Item_types/List.vue'),
        meta: {
            auth: true,
            title: 'Item_types List'
        }
    },
    {
        path: '/item_types-list/:id',
        name: 'ViewItem_types',
        component: () => import('./../views/Item_types/Edit.vue'),
        meta: {
            auth: true,
            title: 'Item_types Edit'
        }
    },
    {
        path: '/item_types_view-list',
        name: 'ListItem_types_view',
        component: () => import('./../views/Item_types_view/ViewList.vue'),
        meta: {
            auth: true,
            title: 'Item_types_view View List'
        }
    },
    {
        path: '/shop_item_histories-list',
        name: 'ListShop_item_histories',
        component: () => import('./../views/Shop_item_histories/List.vue'),
        meta: {
            auth: true,
            title: 'Shop_item_histories List'
        }
    },
    {
        path: '/shop_item_histories-list/:id',
        name: 'ViewShop_item_histories',
        component: () => import('./../views/Shop_item_histories/Edit.vue'),
        meta: {
            auth: true,
            title: 'Shop_item_histories Edit'
        }
    },
    {
        path: '/shop_item_moves-list',
        name: 'ListShop_item_moves',
        component: () => import('./../views/Shop_item_moves/List.vue'),
        meta: {
            auth: true,
            title: 'Shop_item_moves List'
        }
    },
    {
        path: '/shop_item_moves-list/:id',
        name: 'ViewShop_item_moves',
        component: () => import('./../views/Shop_item_moves/Edit.vue'),
        meta: {
            auth: true,
            title: 'Shop_item_moves Edit'
        }
    },
    {
        path: '/shop_items-list',
        name: 'ListShop_items',
        component: () => import('./../views/Shop_items/List.vue'),
        meta: {
            auth: true,
            title: 'Shop_items List'
        }
    },
    {
        path: '/shop_items-view',
        name: 'Shop Items View',
        component: () => import('./../views/Shop_items/View.vue'),
        meta: {
            auth: true,
            title: 'Shop Items View'
        }
    },
    {
        path: '/shop_items-list/:id',
        name: 'ViewShop_items',
        component: () => import('./../views/Shop_items/Edit.vue'),
        meta: {
            auth: true,
            title: 'Shop_items Edit'
        }
    },
    {
        path: '/shop_items_view-list',
        name: 'ListShop_items_view',
        component: () => import('./../views/Shop_items_view/ViewList.vue'),
        meta: {
            auth: true,
            title: 'Shop_items_view View List'
        }
    },
    {
        path: '/shop_options-list',
        name: 'Options List',
        component: () => import('./../views/ShopOptionsView.vue'),
        meta: {
            auth: true,
            title: 'Options List'
        }
    },
    {
        path: '/shop_invoice_items-list',
        name: 'ListShop_invoice_items',
        component: () => import('./../views/Shop_invoice_items/List.vue'),
        meta: {
            auth: true,
            title: 'Shop_invoice_items List'
        }
    },
    {
        path: '/shop_invoice_items-list/:id',
        name: 'ViewShop_invoice_items',
        component: () => import('./../views/Shop_invoice_items/Edit.vue'),
        meta: {
            auth: true,
            title: 'Shop_invoice_items Edit'
        }
    },
    {
        path: '/shop_invoices-list',
        name: 'ListShop_invoices',
        component: () => import('./../views/Shop_invoices/List.vue'),
        meta: {
            auth: true,
            title: 'Shop_invoices List'
        }
    },
    {
        path: '/shop_invoices_direct-list',
        name: 'ListShop_invoices',
        component: () => import('./../views/Shop_invoices/ListDirectSale.vue'),
        meta: {
            auth: true,
            title: 'Shop_invoices Direct'
        }
    },
    {
        path: '/shop_invoices_view-list',
        name: 'ListInvoice',
        component: () => import('./../views/Shop_invoices/ListInvoice.vue'),
        meta: {
            auth: true,
            title: 'Shop_invoices List'
        }
    },
    {
        path: '/shop_invoices-list/:id',
        name: 'ViewShop_invoices',
        component: () => import('./../views/Shop_invoices/Edit.vue'),
        meta: {
            auth: true,
            title: 'Shop_invoices Edit'
        }
    },

    {
        path: '/driver_bag_orders-list',
        name: 'ListDriver_bag_orders',
        component: () => import('./../views/Driver_bag_orders/List.vue'),
        meta: {
            auth: true,
            title: 'Driver_bag_orders List'
        }
    },
    {
        path: '/driver_bag_orders-list/:id',
        name: 'ViewDriver_bag_orders',
        component: () => import('./../views/Driver_bag_orders/Edit.vue'),
        meta: {
            auth: true,
            title: 'Driver_bag_orders Edit'
        }
    },
    {
        path: '/driver_bags-list',
        name: 'ListDriver_bags',
        component: () => import('./../views/Driver_bags/List.vue'),
        meta: {
            auth: true,
            title: 'Driver_bags List'
        }
    },
    {
        path: '/driver_bags-view',
        name: 'ListViewDriver_bags',
        component: () => import('./../views/Driver_bags/ListView.vue'),
        meta: {
            auth: true,
            title: 'Driver_bags List'
        }
    },
    {
        path: '/driver_bags-list/:id',
        name: 'ViewDriver_bags',
        component: () => import('./../views/Driver_bags/Edit.vue'),
        meta: {
            auth: true,
            title: 'Driver_bags Edit'
        }
    },
    {
        path: '/drivers-list',
        name: 'ListDrivers',
        component: () => import('./../views/Drivers/List.vue'),
        meta: {
            auth: true,
            title: 'Drivers List'
        }
    },
    {
        path: '/drivers-list/:id',
        name: 'ViewDrivers',
        component: () => import('./../views/Drivers/Edit.vue'),
        meta: {
            auth: true,
            title: 'Drivers Edit'
        }
    },

    {
        path: '/shipped_packages-list',
        name: 'ListShipped_packages',
        component: () => import('./../views/Shipped_packages/List.vue'),
        meta: {
            auth: true,
            title: 'Shipped_packages List'
        }
    },
    {
        path: '/shipped_packages-list/:id',
        name: 'ViewShipped_packages',
        component: () => import('./../views/Shipped_packages/Edit.vue'),
        meta: {
            auth: true,
            title: 'Shipped_packages Edit'
        }
    },
    {
        path: '/shipping_package_files-list',
        name: 'ListShipping_package_files',
        component: () => import('./../views/Shipping_package_files/List.vue'),
        meta: {
            auth: true,
            title: 'Shipping_package_files List'
        }
    },
    {
        path: '/shipping_package_files-list/:id',
        name: 'ViewShipping_package_files',
        component: () => import('./../views/Shipping_package_files/Edit.vue'),
        meta: {
            auth: true,
            title: 'Shipping_package_files Edit'
        }
    },
    {
        path: '/customer_users-list',
        name: 'ListCustomer_users',
        component: () => import('./../views/Customer_users/List.vue'),
        meta: {
            auth: true,
            title: 'Customer_users List'
        }
    },
    {
        path: '/customer_users-list/:id',
        name: 'ViewCustomer_users',
        component: () => import('./../views/Customer_users/Edit.vue'),
        meta: {
            auth: true,
            title: 'Customer_users Edit'
        }
    },




]
const router = new VueRouter({
    routes,
    mode: 'history'
})

export default router
