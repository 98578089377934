import { initializeApp } from "firebase/app";
import "firebase/auth";
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBkqrAA_u0K4WT4-vAckbRK1oxko2Dv8hI",
    authDomain: "elly-store.firebaseapp.com",
    projectId: "elly-store",
    storageBucket: "elly-store.appspot.com",
    messagingSenderId: "721780312943",
    appId: "1:721780312943:web:9b726fb915507fb3e2769a",
    measurementId: "G-GC56D70XNC"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

export { auth, app, signInWithEmailAndPassword, sendPasswordResetEmail }
