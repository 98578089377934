
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueApexCharts from 'vue-apexcharts'

import './assets/main.css' 
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false
import axios from 'axios'


// let api = 'http://localhost:3000/'
// Vue.prototype.$imageURL = 'http://localhost:3000/'

// let api = 'http://192.168.0.102:3000/'
let api = 'https://api.ellyonlinestore.com'

if (process.env.NODE_ENV === 'production') {
    api = 'https://api.ellyonlinestore.com/'
}

Vue.prototype.$imageURL = api

axios.defaults.baseURL = api;
axios.defaults.headers.post['Content-Type'] = 'application/json';



new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
        
        