
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconFont: "md",
    },
    theme: {
        themes: {
            // dark: true,
            light: {

                primary: '#626ED4',
                lprimary: '#B1B7EA',
            },
            dark: {
                primary: '#626ED4',
                lprimary: '#B1B7EA',
            },
        }
    }

});  
