
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import languages from './languages.json'
import { auth } from '../firebase'
import router from '../router/index'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {

        auth: {
            isAuth: false,
            user: null
        },

        init_state: false,


        languages: languages,
        language: languages[0],

        customers_list: [],

        file_attachments_list: [],

        order_items_list: [],

        orders_list: [],

        shop_sales_list: [],

        packed_items_list: [],

        packed_orders_list: [],

        users_list: [],

        store_information_list: [],

        user: {},

        item_colors_list: [],

        item_colors_view_list: [],

        item_sizes_list: [],

        item_sizes_view_list: [],

        item_types_list: [],

        item_types_view_list: [],

        shop_item_histories_list: [],

        shop_item_moves_list: [],

        shop_items_list: [],

        shop_items_view_list: [],

        shop_invoice_items_list: [],

        shop_invoices_list: [],

        driver_bag_orders_list: [],

        driver_bags_list: [],

        drivers_list: [],

        shipped_packages_list: [],

        shipping_package_files_list: [],


    },
    getters: {
        language: state => state.language,
        languages: state => state.languages,

        auth: state => state.auth,


        customers_list: state => state.customers_list,

        file_attachments_list: state => state.file_attachments_list,

        order_items_list: state => state.order_items_list,

        orders_list: state => state.orders_list,

        shop_sales_list: state => state.shop_sales_list,

        users_list: state => state.users_list,
        user: state => state.user,

        packed_items_list: state => state.packed_items_list,

        packed_orders_list: state => state.packed_orders_list,

        store_information_list: state => state.store_information_list,

        item_colors_list: state => state.item_colors_list,

        item_colors_view_list: state => state.item_colors_view_list,

        item_sizes_list: state => state.item_sizes_list,

        item_sizes_view_list: state => state.item_sizes_view_list,

        item_types_list: state => state.item_types_list,

        item_types_view_list: state => state.item_types_view_list,

        shop_item_histories_list: state => state.shop_item_histories_list,

        shop_item_moves_list: state => state.shop_item_moves_list,

        shop_items_list: state => state.shop_items_list,

        shop_items_view_list: state => state.shop_items_view_list,

        shop_invoice_items_list: state => state.shop_invoice_items_list,

        shop_invoices_list: state => state.shop_invoices_list,

        driver_bag_orders_list: state => state.driver_bag_orders_list,

        driver_bags_list: state => state.driver_bags_list,

        drivers_list: state => state.drivers_list,

        shipped_packages_list: state => state.shipped_packages_list,

        shipping_package_files_list: state => state.shipping_package_files_list,
    },
    mutations: {


        setAuth(state, payload) {
            state.auth.user = payload.user
            state.auth.isAuth = true
            localStorage.setItem('auth', JSON.stringify(auth))
        },

        setLanguage(state, language) {
            state.language = language
        },

        setCustomersList(state, customers_list) {
            state.customers_list = customers_list
        },

        setFileAttachmentsList(state, file_attachments_list) {
            state.file_attachments_list = file_attachments_list
        },

        setOrderItemsList(state, order_items_list) {
            state.order_items_list = order_items_list
        },

        setOrdersList(state, orders_list) {
            state.orders_list = orders_list
        },

        setShopSalesList(state, shop_sales_list) {
            state.shop_sales_list = shop_sales_list
        },

        setUsersList(state, users_list) {
            state.users_list = users_list
        },
        setPackedItemsList(state, packed_items_list) {
            state.packed_items_list = packed_items_list
        },

        setPackedOrdersList(state, packed_orders_list) {
            state.packed_orders_list = packed_orders_list
        },
        setStoreInformation(state, store_information_list) {
            state.store_information_list = store_information_list
        },

        setItemColorsList(state, item_colors_list) {
            state.item_colors_list = item_colors_list
        },

        setItemColorsViewList(state, item_colors_view_list) {
            state.item_colors_view_list = item_colors_view_list
        },

        setItemSizesList(state, item_sizes_list) {
            state.item_sizes_list = item_sizes_list
        },

        setItemSizesViewList(state, item_sizes_view_list) {
            state.item_sizes_view_list = item_sizes_view_list
        },

        setItemTypesList(state, item_types_list) {
            state.item_types_list = item_types_list
        },

        setItemTypesViewList(state, item_types_view_list) {
            state.item_types_view_list = item_types_view_list
        },

        setShopItemHistoriesList(state, shop_item_histories_list) {
            state.shop_item_histories_list = shop_item_histories_list
        },

        setShopItemMovesList(state, shop_item_moves_list) {
            state.shop_item_moves_list = shop_item_moves_list
        },

        setShopItemsList(state, shop_items_list) {
            state.shop_items_list = shop_items_list
        },

        setShopItemsViewList(state, shop_items_view_list) {
            state.shop_items_view_list = shop_items_view_list
        },

        setShopInvoiceItemsList(state, shop_invoice_items_list) {
            state.shop_invoice_items_list = shop_invoice_items_list
        },

        setShopInvoicesList(state, shop_invoices_list) {
            state.shop_invoices_list = shop_invoices_list
        },

        setDriverBagOrdersList(state, driver_bag_orders_list) {
            state.driver_bag_orders_list = driver_bag_orders_list
        },

        setDriverBagsList(state, driver_bags_list) {
            state.driver_bags_list = driver_bags_list
        },

        setDriversList(state, drivers_list) {
            state.drivers_list = drivers_list
        },
        setShippedPackagesList(state, shipped_packages_list) {
            state.shipped_packages_list = shipped_packages_list
        },

        setShippingPackageFilesList(state, shipping_package_files_list) {
            state.shipping_package_files_list = shipping_package_files_list
        },


    },
    actions: {
        init(context, uid) {
            const id = uid
            // const id = 'yn5lsqU1MwZPOPfSAUR7tmQ8be82'
            axios.get('init/' + id)
                .then(r => {
                    if (r.status == 200 && r.data) {
                        context.commit('setAuth', {
                            user: r.data.user
                        })
                        // context.commit('setCustomersList', r.data.customers)
                        context.commit('setUsersList', r.data.users)
                        // context.commit('setOrdersList', r.data.orders)
                        context.commit('setStoreInformation', r.data.store_information)
                        // context.commit('setItemColorsList', r.data.item_colors)
                        // context.commit('setItemTypesList', r.data.item_types)
                        // context.commit('setItemSizesList', r.data.item_sizes)
                        // context.commit('setShopItemsList', r.data.shop_items)
                        context.commit('setDriversList', r.data.drivers)
                        context.state.user = r.data.user
                        context.state.init_state = true
                    } else {

                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        logout(context) {
            auth.signOut()
            localStorage.removeItem('auth')
            context.state.auth.isAuth = false
            router.push('/login')
            context.state.init_state = true
        },
    },
})
